import React from 'react';
import styles from "./Header.module.scss";
import Image from "next/legacy/image";
import profileImg from '../../public/avatar-creator.jpg'
import verified from '../../public/img/verify.svg'
import Link from "next/link";
import { useTranslation } from "next-i18next";

function CollectionsRowItem({ col, sendLink }) {
    const { t } = useTranslation("common");
    return (
        <Link href="#"
              onClick={() => sendLink("/nft/collections/" + decodeURI(col.collection_slug))}>
            <div className={styles.searchList}>
                    <div className="d-flex">
                        <Image src={col.image_url ? col.image_url : profileImg} alt="UpYo NFT"
                            width={32} height={32} layout="fixed" />
                        <div className={styles.list}>
                            <div className="d-flex align-items-center">
                                <span className={styles.name}>
                                    {col.collection_name}
                                    {col.isVerified && <Image src={verified}
                                        alt={t('common:all_collections')}
                                        width="16"
                                        height="16" />
                                    }
                                </span>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center ms-1">
                                    {col.totalCollectionNfts}
                                    <span className='ms-1'>{t('common:items')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Link>
    );
}

export default CollectionsRowItem;